import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Card } from "antd"
import styled from "styled-components"

const CustomCard = styled(Card.Grid)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-top: 15px;
  @media only screen and (max-width: 1400px) {
    flex-direction: column;
  }
`
const TeamText = styled.div`
  width: 60%;
  text-align: justify;
  margin: auto;
  white-space: pre-wrap;
  @media only screen and (max-width: 1400px) {
    margin-top: 20px;
    width: 100%;
  }
`
const imageStyle = {
  borderRadius: "10px",
  maxWidth: "280px",
  margin: "auto",
}

export const TeamCard = props => {
  return props.data.map(data => {
    const image = getImage(data.imageData)
    return (
      <CustomCard hoverable={true} key={data.heading}>
        <GatsbyImage
          image={image}
          alt={data.imageData.name}
          style={imageStyle}
        />
        {data.bodyText || data.heading ? (
          <TeamText>
            {data.heading && <h2>{data.heading}</h2>}
            {data.bodyText && <p>{data.bodyText}</p>}
          </TeamText>
        ) : (
          ``
        )}
      </CustomCard>
    )
  })
}
