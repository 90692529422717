import * as React from "react"
import { Card } from "antd"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { TeamCard } from "../components/TeamCard"
import { graphql } from "gatsby"

const ImageContainer = styled(Card.Grid)`
  width: 100%;
`
const headStyle = {
  fontFamily: "JosefinSans",
  fontSize: "6vw",
  textAlign: "center",
  borderBottom: "0px",
  marginBottom: "-50px",
}

const About = ({ data }) => {
  const teamCardData = [
    {
      imageData: data.allFile.edges.find(
        file => file.node.name === "team2-crop"
      ).node,
      heading: "Tarun Jangra - Director",
      bodyText:
        "Holds a degree in Bachelor of Architecture from University of Kerala, India in the year 1997. Established own Architectural firm The Design Grid in 1999 in Chandigarh designing projects ranging from Houses, Corporate Office Interiors, Retail Interiors, Industrial and Commercial Buildings. Major Clients included, LG Electronics, Havell's India Ltd, Toubro Industries, Cheema Spintex, Torque Pharmaceuticals, Allengers Medical Systems.\n\nAfter moving to Australia in 2006 completed Building Designers Course to practice as registered Building Designer. Having designed number of Residential and Industrial projects in Brisbane and surrounding areas, eagerness to deliver creative and innovative solutions based on clients specific needs and requirements to better the experience to reside/use the build form.",
    },
    {
      imageData: data.allFile.edges.find(
        file => file.node.name === "team3-crop"
      ).node,
      heading: "Sonika Jangra - Director",
      bodyText:
        "Holds a degree Bachelor of Architecture from Chandigarh College of Architecture in 1996.\n\nSonika has invaluable knowledge and experience in architecture having practiced in architecture field in Chandigarh (India) and in Brisbane. Her work experience ranges from designing Residential, Commercial, Interior and Industrial buildings.",
    },
    {
      imageData: data.allFile.edges.find(
        file => file.node.name === "team4-crop"
      ).node,
      heading: "Chong Kim - Associate",
      bodyText:
        "Chong has recently finished his Masters Degree in Architecture from University of Queensland and has started here as fresh graduate. Chong is creative and is quick learner willing to take up any challenge thrown at him.",
    },
    {
      imageData: data.allFile.edges.find(
        file => file.node.name === "team5-crop"
      ).node,
      heading: "Supreet Bedi - Associate",
      bodyText:
        "Supreet completed his bachelor degree in architecture from Punjab Technical University (India) in 2009. Supreet is very sharp minded and takes pride in his work.",
    },
  ]
  return (
    <Card title="THE TEAM" headStyle={headStyle}>
      <ImageContainer hoverable={false}>
        <StaticImage
          src="../images/team-images/team1-crop.jpg"
          alt="team-image"
          layout="fullWidth"
          aspectRatio={30 / 9}
        />
      </ImageContainer>
      <TeamCard data={teamCardData} />
    </Card>
  )
}
export const pageQuery = graphql`
  query {
    allFile(filter: { sourceInstanceName: { eq: "teamImages" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(
              width: 280
              height: 280
              placeholder: BLURRED
              quality: 90
            )
          }
          name
        }
      }
    }
  }
`
export default About
